import { ReactNode } from 'react'
import { ICoordinates, IPhotoInput, SizeFilter, SortBy } from './graphql'

export type IBounds = {
  latTop: number
  latBot: number
  lngLeft: number
  lngRight: number
}

export type IMarker = { id: string; coordinates: ICoordinates }

export type IQueryParams = {
  search: string | undefined
  placeId: string | undefined
  description: string | undefined

  lat: number | undefined
  lng: number | undefined
  zoom: number | undefined

  sortBy: SortBy | undefined

  minPrice: number | undefined
  maxPrice: number | undefined
  canStoreVehicle: boolean | undefined
  size: SizeFilter | undefined

  page: number
}

export type IPrivatePhotoInput = IPhotoInput & { signedUrl?: string | null }

export enum RedirectUrls {
  Home = '/',

  TermsAndConditions = '/terminos-y-condiciones',
  Disclosures = '/aviso-de-privacidad',
  Login = '/login',

  Anfitrion = '/anfitrion',
  Negocio = '/negocio',

  Directory = '/directory',

  Help = '/acerca-de-spotme',
  Blog = '/blog',

  Refer = '/invita',

  Search = '/search',
  Details = '/alojamientos',
  User = '/user',
  Bussiness = '/bussiness',
  CheckoutDetails = '/checkoutDetails',

  Dashboard = '/dashboard/host',
  DashboardRent = '/dashboard/rent',
  Listings = '/dashboard/host/listings',
  Rents = '/dashboard/host/rents',
  HostProfile = '/dashboard/host/profile',
  HostEditProfile = '/dashboard/host/editProfile',
  HostMessages = '/dashboard/host/messages',
  Referral = '/dashboard/host/referral',

  CompleteProfile = '/complete-profile',

  Reservations = '/dashboard/rent/reservations',
  RentMessages = '/dashboard/rent/messages',
  RentProfile = '/dashboard/rent/profile',
  RentEditProfile = '/dashboard/rent/editProfile',

  BankInfo = '/dashboard/bankInfo',
  EditBankInfo = '/dashboard/editBankInfoUser',
  Admin = '/dashboard/admin',

  UserConfirmation = '/userConfirmation',
  SubmitLocation = '/post',
  Checkout = '/checkout',
  PhonePrompt = '/phoneConfirmation'
}

export enum IFilterCanStoreVehicle {
  Both = 'BOTH',
  Vehicle = 'VEHICLE',
  SelfStorage = 'SELF_STORAGE'
}

export enum CheckoutView {
  Reservation = 'reservation',
  Rent = 'rent'
}

export type IArticle = {
  category: RedirectUrls
  article: { header: string; content: string | string[] | ReactNode }[]
}

export type IArticleSearchable = {
  title: string
  description: string
  href: RedirectUrls
  body: string
}
